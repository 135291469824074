import * as React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";

const levels = {
  1: "beginner",
  2: "proficient",
  3: "intermediate",
  4: "advanced",
  5: "native",
};

const skills = [
  { id: 0, name: "English", level: 5 },
  { id: 1, name: "Portuguese", level: 5 },
  { id: 2, name: "SQL", level: 4 },
  { id: 3, name: "Python", level: 4 },
  { id: 4, name: "Javascript", level: 4 },
  { id: 5, name: "Elixir", level: 2 },
  { id: 6, name: "Go", level: 3 },
  { id: 7, name: "Retool", level: 4 },
  { id: 8, name: "React", level: 3 },
  { id: 9, name: "GatsbyJS", level: 3 },
  { id: 10, name: "DBT", level: 4 },
  { id: 11, name: "Airflow", level: 4 },
  { id: 12, name: "DuckDB", level: 3 },
  { id: 13, name: "Meltano", level: 3 },
];

const jobs = [
  {
    order: 0,
    title: "Software Engineer",
    company: "Baton",
    tenure: "April 2024 – Present",
    bullets: ["Front-end", "Back-end", "Data", "etc."],
  },
  {
    order: 1,
    title: "Senior Data Engineer",
    company: "Remote",
    tenure: "Nov 2020 – April 2024",
    bullets: [
      "1st data hire: internal tools, automations, pipelines, reporting",
      "Designed, built, and implemented auditing tools and automations to ensure data quality in critical manual processes.",
      "Turned an ad hoc audit log into reliable structured data for analysts to more easily report on changes to records.",
      "Scaled data infrastructure to meet the needs of the company as it grew from 50 employees to > 1,000",
    ],
  },
  {
    order: 2,
    title: "Engineering Manager",
    company: "Embarc Collective",
    tenure: "Aug 2018 – Nov 2020",
    bullets: [
      "Responsible for all internal tools and reporting.",
      "Resource for over 50 early-stage startups, helping them work through problems from process automation to product & engineering strategy.",
      "Built several public-facing projects, including tampabaystartupjobs.com, tampabayeats.org, and reports.embarccollective.com. Also wrote the reports.",
    ],
  },
  {
    order: 3,
    title: "Software Engineer",
    company: "Freelancer",
    tenure: "Jun 2018 – Aug 2018",
    bullets: [
      "Focused on clients that needed to extend or alter the functionality of their off-the-shelf CRMs.",
      "Notable project: designed & built a chrome extension for a research team at UC Berkeley that needed to hide or modify content on a customer support interface.",
      "Consistently hit milestones ahead of time and under budget.",
    ],
  },
  {
    order: 4,
    title: "Engineering Manager",
    company: "Pack Health",
    tenure: "Aug 2016 – Jun 2018",
    bullets: [
      "Joined as a 2016 Venture for America Fellow.",
      "Started as a product associate, pivoted to engineering and led a shift to building software internally.",
      "Designed and built a patient-facing education and survey platform, leading to higher patient engagement and course completion (also did voiceovers for the videos).",
      "Built automated tools to help health coaches more efficiently engage with their patients.",
      "Helped hire a team and hand off my responsibilities as my 2-year fellowship came to a close.",
    ],
  },
];

const Skill = styled.li`
  text-decoration: 'none'};
  margin: 10px 10px 0 0;
  padding: 0 10px;
  color: var(--color-background);
  background-color: var(--color-secondary);
`;

const Skills = styled.ul`
  min-width: 250px;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  list-style-type: none;
`;

const Resume = styled.div`
  margin: 1rem;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
`;
const RowItem = styled.div`
  margin: 1rem;
`;
const Education = styled(RowItem)`
  min-width: 250px;
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;
const Title = styled(RowItem)`
  h1 {
    margin-bottom: 5px;
  }
  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  p {
    margin-top: 7px;
    margin-bottom: 0;
  }
`;

const Job = styled(Row)`
  align-items: start;
  margin: 1rem 1rem 1rem 0;
`;

const JobTitle = styled(RowItem)`
  margin-left: 0;
  h2,
  h4,
  p {
    margin: 10px 0;
  }
`;

const JobDetails = styled(RowItem)`
  width: 60%;
  min-width: 300px;
  ul {
    padding: 0 1rem;
  }
`;

const ResumePage = () => {
  return (
    <Layout pageTitle="Fabio de Sousa's Résumé">
      <Resume>
        <Row>
          <Title>
            <h1>Fabio de Sousa</h1>
            <h3>Software Engineer</h3>
            <p>Open to remote work.</p>
          </Title>
        </Row>
        <Row>
          <RowItem style={{ flex: "1 1 0" }}>
            <h2 style={{ margin: 0 }}>Skills</h2>
            <Skills>
              {skills
                .sort((a, b) => b.level - a.level)
                .map((skill) => (
                  <Skill key={skill.id} title={levels[skill.level]}>
                    {skill.name}
                  </Skill>
                ))}
            </Skills>
          </RowItem>
          <Education style={{ flex: "1 1 0" }}>
            <h2>Education</h2>
            <h4>Columbia University, May 2016</h4>
            <p>BA in Sociology, Magna cum laude</p>
          </Education>
        </Row>
        <Row>
          <RowItem>
            <h1>Work History</h1>
            {jobs
              .sort((a, b) => a.order - b.order)
              .map((job) => (
                <Job>
                  <JobTitle>
                    <h2>{job.title}</h2>
                    <h4>{job.company}</h4>
                    <p>{job.tenure}</p>
                  </JobTitle>
                  <JobDetails>
                    <ul>
                      {job.bullets.map((bullet) => (
                        <li>{bullet}</li>
                      ))}
                    </ul>
                  </JobDetails>
                </Job>
              ))}
          </RowItem>
        </Row>
      </Resume>
    </Layout>
  );
};

export default ResumePage;
